
.register-id-popup{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:10;
  background-color:rgba(0,0,0,0.5);
  display:flex;
  justify-content:center;
  align-items:center;
  @media(max-width:768px){
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:10;
    background-color:rgba(0,0,0,0.5);
    display:flex;
    justify-content:center;
    align-items:flex-start;
  }
}
.register-id-popup-container{
  width:700px;
  height:auto;
  background-color:#FFFFFF;
  border: 2px solid #b12583;
  padding:25px;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  &.register-id-change-password{
    height:auto;
    box-sizing: border-box;
  }
  @media(max-width:768px){
    width:700px;
    height:auto;
    background-color:#FFFFFF;
    border: 2px solid #b12583;
    padding:15px 5px;
    margin:150px 10px 0 10px;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
  }
}
.register-id-number-container{
  width:100%;
  display:flex;
  justify-content:space-between;
  align-items:center;
}
.register-id-close-container{
  width:100%;
  display:flex;
  justify-content:flex-end;
  align-items:center;
}
.register-id-close-button{
  width:25px;
  height:25px;
  cursor:pointer;
}
.register-id-popup-header{
  font-family:'Inter', sans-serif;
  font-weight:700;
  font-size:36px;
  color:#1C1B1F;
  width:100%;
  margin:20px 0;
  text-align:left;
}
.register-id-popup-text{
  margin:10px 0 20px 0;
  font-family:'Inter', sans-serif;
  font-weight:400;
  font-size:20px;
  color:#1C1B1F;
  text-align: left;
  width:100%;
}
.register-id-popup-content{
  width:100%;
  display:flex;
  padding:0 20px ;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  @media(max-width:768px){
    padding:0 10px;
  }
}
.register-id-buttons-container{
  width:100%;
  display:flex;
  justify-content:flex-end;
  align-items:center;
  margin-top:20px;
}
.register-id-abort{
  font-family:'Inter', sans-serif;
  font-weight:400;
  font-size:20px;
  color:#1C1B1F;
  margin:0 10px;
  cursor:pointer;
}

