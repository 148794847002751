.single_auth_code_input {
  width: 45px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  margin: 0 3px;
  outline: none;
  border: none;
  font-size: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  padding: 0;
}
.single_auth_code_input:focus {
  background: rgb(233, 245, 252);
}

.single_auth_code_input:nth-child(4) {
  margin-right: 30px;
}
.single_auth_code_input:nth-child(8) {
  margin-right: 30px;
}

.single_auth_code_input_mobile {
  width: 20px;
  height: 50px;
  color: black;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  border: none;
  margin: 5px 0;
}

.single_auth_code_input_mobile:nth-child(1) {
  margin-left: calc(((((100vw - 66px) / 3) - 80px) / 2) - 4px);
}
.single_auth_code_input_mobile:nth-child(4) {
  margin-right: calc((((100vw - 66px) / 3) - 80px) + 2px);
}
.single_auth_code_input_mobile:nth-child(8) {
  margin-right: calc((((100vw - 66px) / 3) - 80px) + 2px);
}

@media only screen and (max-width: 332px) {
  .single_auth_code_input_mobile {
    width: 15px;
    font-size: 18px;
  }

  .single_auth_code_input_mobile:nth-child(1) {
    margin-left: calc(((((100vw - 66px) / 3) - 60px) / 2) - 2px);
  }
  .single_auth_code_input_mobile:nth-child(4) {
    margin-right: calc((((100vw - 66px) / 3) - 60px) + 2px);
  }
  .single_auth_code_input_mobile:nth-child(8) {
    margin-right: calc((((100vw - 66px) / 3) - 60px) + 2px);
  }
}
