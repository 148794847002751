#pageWrapper{
  width:100%;
  height:100%;
}
$large: 1440px;
$medium: 1024px;
$small: 768px;
$sm: 768px;
$md: 965px;
$lg: 1240px;
$green:#129612;
$green-hover:#138E13;
$graphite: rgb(29, 29, 29);
.wrapper{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container{
  width: 100%;
  max-width: 822px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 33px;
}
.error{
  color:red;
  font-size:12px;
  font-family:'Poppins', sans-serif;
  margin-bottom:10px;
  &.success{
    color: forestgreen;
  }
}
.global-container{
  max-width: 1440px;
  width: 100%;
  min-height: calc(100vh - 300px);
  height:100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top:85px;
  margin:0 auto;
  display:flex;
  justify-content: space-between;
  align-items: flex-start;
}
.global-container-column{
  width: 50%;
  height: 100%;
  padding:25px 10px;
  @media (max-width: var(--small)) {
    width: 100%;
    height: 50%;
  }
  @media(max-width: 768px){
    width: 100%;
    padding-right: 0;
  }
}
.global-input{
  height: 40px;
  background: #ffffff;
  border: 1px solid $graphite;
  border-radius: 8px;
  min-width:80px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content:center;
  width: 100%;
  padding: 10px 22px;
  margin-bottom:12px;
  &.disabled{
    background:#D9D9D9;
  }
  @media(max-width: 576px){
    font-size: 16px;
  }
  @media(max-width: 400px){
    font-size: 14px;
  }
  &.error{
    border:1px solid red;
  }
}
.global-container{
  max-width: 1440px;
  width: 100%;
  min-height: calc(100vh - 300px);
  padding-left: 15px;
  padding-right: 15px;
  padding-top:60px;
  margin: 0 auto 30px;
}
.global-wrapper{
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  @media (max-width: var(--medium)) {
    padding: 0 24px;
  }
  &.popup{
    position: relative;
  }
}
.global-row{
  width: 100%;
  height: 100%;
  display: flex;
  @media (max-width: var(--small)) {
    flex-direction: column;
    padding-top: 20px;
  }
  @media(max-width: 768px){
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }
}
.global-column{
  width: 50%;
  height: 100%;
  @media (max-width: var(--small)) {
    width: 100%;
    height: 50%;
  }
  @media(max-width: 768px){
    width: 100%;
    padding-right: 0;
  }

}
.global-user-column{
  width: 48%;
  height: 100%;
  @media (max-width: var(--small)) {
    width: 100%;
    height: 50%;
  }
  @media(max-width: 768px){
    width: 100%;
  }
}
.global-user-pet-id-registered-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  position:relative;
  justify-content: space-between;
  @media(max-width: 768px){
    flex-direction: column;
  }
}
.global-my-data-input{
  height: 49px;
  background: #ffffff;
  border: 2px solid #b12583;
  border-radius: 10px;
  width:100%;
  padding: 10px 22px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  &.disabled{
    background: #D9D9D9;
  }
  @media(max-width: 576px){
    font-size: 16px;
  }
  @media(max-width: 400px){
    font-size: 14px;
  }

}
//.global-input{
//  height: 49px;
//  background: #FFFFFF;
//  border: 2px solid #b12583;
//  border-radius: 10px;
//  width:100%;
//  padding: 10px 22px;
//  font-family: 'Inter', sans-serif;
//  font-style: normal;
//  font-weight: 700;
//  font-size: 20px;
//  line-height: 24px;
//  display: flex;
//  align-items: center;
//  &.disabled{
//    background: #D9D9D9;
//  }
//  &.center{
//    width:84%;
//  }
//  @media(max-width: 576px){
//    font-size: 16px;
//  }
//  @media(max-width: 400px){
//    font-size: 14px;
//  }
//}
.global-success-msg{
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  color: forestgreen;
}
.global-text-area{
  height: 200px;
  resize: none;
  background: #ffffff;
  border: 2px solid #b12583;
  border-radius: 10px;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding: 10px 22px;
}
.global-two-inputs{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  &.date-input{
    @media(max-width: 991px){
      flex-direction: column;
      align-items: flex-start;
    }
  }
  @media(max-width: 868px){
    flex-direction: column;
    align-items: flex-start;
  }
}
.global-inputs-container{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 48%;
  flex-direction: column;
  @media(max-width: 868px){
    width: 100%;
  }
}
.global-longer-input{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 57%;
  height:100%;
  flex-direction: column;
  &.center{
    justify-content: center;
  }
  @media(max-width: 868px){
    width: 100%;
  }
  @media(max-width: 1184px){
    width: 57%;
  }
  @media(max-width: 991px){
    width: 80%;
  }
  @media(max-width: 768px){
    width: 60%;
  }
  @media(max-width: 568px){
    width: 100%;
  }
}
.global-shorter-input{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 39%;
  flex-direction: column;
  @media(max-width: 868px){
    width: 100%;
  }
  @media(max-width: 1184px){
    width: 40%;
  }
  @media(max-width: 991px){
    width: auto;
  }
  &.calendar-input{
    @media(max-width: 991px){width: 200px;}
  }
}
.global-select{
  height: 49px;
  background: #ffffff;
  border: 2px solid #b12583;
  border-radius: 10px;
  min-width:80px;
  margin-right:5px;
  width:100%;
  padding:10px 22px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align:center;
  line-height: 24px;
  display: flex;
  align-items: center;
  &.center{
    width:15%;
    padding:10px 5px;
    justify-content: center;
  }
  option{
    width:100px;
  }
}
.global-full-page-box{
  width: 100%;
  @media (max-width: var(--medium)) {
    padding-bottom: 100px;
  }
}
.global-form{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.global-title{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #1c1b1f;
  @media(max-width: 1028px){
    font-size: 28px
  }
  @media(max-width: 576px){
    font-size: 26px;
    line-height: 34px;
  }
}

.global-authorization-desc{
  font-family: "Inter",sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1c1b1f;
}
.global-error{
  font-family: "Inter",sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: red;
  margin-bottom: 5px;
}
.global-success{
  font-family: "Inter",sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
  color: forestgreen;
}
.global-regulations-wrapper{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}
.global-regulations-title{
  font-family: "Inter",sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1c1b1f;
  text-align: center;
}
.global-label{
  font-family: "Inter",sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1c1b1f;
  text-align: start;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 1em;
  @media(max-width: 576px){
    span{
      display: block;
      margin-left: 10px;
      font-size: 16px;
    }

  }
  @media(max-width: 400px){
    font-size: 14px;
    gap: 2em;
  }
}
.global-accept-regulations{
  border: 2px solid #b12583;
  border-radius: 10px;
  height: 34px;
  width: 34px;
  transform: translateY(-0.075em);
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin:0;
  display: grid;
  place-content: center;

  &::before {
    content: "";
    width: 26px;
    border-radius: 10px;
    height: 26px;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #b12583;
  }

  &:checked {
    &::before {
      transform: scale(1)
    }
  }
}
.global-input-title{
  font-family: "Inter",sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-left: 20px;
  color: #1c1b1f;
  margin-bottom: 3px;
  @media(max-width: 991px){
    margin-left: 0;
    white-space: nowrap;
  }
}
.global-my-profile-bottom-buttons-wrapper{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
}
.global-add-id-bottom-buttons-wrapper{
  justify-content: flex-end;
  width:100%;
  align-items:flex-end;
  display:flex;
  margin-bottom:100px;
}
