.contact-container{
  min-height:800px;
  width:100%;
  padding:110px 25px;
  @media(min-width:$sm){
    height:100vh;
    padding:110px 150px;
  }
  .contact-header{
    font-size:37px;
    font-family:'Poppins', sans-serif;
    font-weight:700;
    text-align: center;
    color:$graphite;
    margin-bottom:25px;
  }
  .contact-text{
    font-size:20px;
    font-family:'Poppins', sans-serif;
    font-weight:400;
    color:$graphite;
    text-align: center;
    margin-bottom:80px;
  }
  .contact-form{
    display:flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    align-items:center;
    gap:15px;
    .contact-input{
      height:40px;
      width:100%;
      border:1px solid $graphite;
      border-radius:8px;
      padding:0 15px;
      font-size:15px;
      font-family: 'Poppins', sans-serif;
      &::placeholder{
        font-size:15px;
        font-family: 'Poppins', sans-serif;
        font-weight:400;
        color:#979797;
        line-height:15px;
      }
      @media(min-width:$sm){
        max-width:600px;
      }
    }
    .contact-textarea{
      height:250px;
      width:100%;
      border:1px solid $graphite;
      border-radius:8px;
      padding:15px;
      font-size:15px;
      font-family: 'Poppins', sans-serif;
      &::placeholder{
        font-size:15px;
        font-family: 'Poppins', sans-serif;
        font-weight:400;
        color:#979797;
        line-height:15px;
      }
      @media(min-width:$sm){
        max-width:600px;
      }
    }
    .info{
      font-family:'Poppins', sans-serif;
      font-size:15px;
      font-weight:400;
      width:100%;
      text-align:left;
      &.error{
        color:red;
      }
      &.success{
        color:green;
      }
    }
    .contact-button{
      width:270px;
      height:50px;
      border-radius:100px;
      border:none;
      outline:none;
      background:$green;
      font-size:19px;
      color:white;
      font-family:'Poppins', sans-serif;
      font-weight:500;
      &:hover{
        background:$green-hover;
      }
    }
  }
}
