.dog-profile-container{
  width:100%;
  height:100%;
  min-height:1000px;
  display:flex;
  justify-content:center;
  align-items:flex-start;
  padding:80px 0;
  &.ignore{
    padding:0
  }
  @media(max-width: 660px){
    flex-direction: column-reverse;
  }
  .dog-profile-sides{
    width:65%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    &.smaller{
      width:35%;
    }
    @media(max-width: 660px){
      width: 100%;
    }
    .dog-profile-sides-header{
      font-family:'Inter', sans-serif;
      font-weight:700;
      font-size:36px;
      color:black;
      margin:30px 0 15px;
      display:flex;
      justify-content:flex-start;
      align-items:center;
      width:100%;
      text-align:left;

      @media(max-width: 991px){
        font-size: 30px;
      }
      @media(max-width: 768px){
        font-size: 28px;
      }
      @media(max-width: 660px){
        flex-wrap: wrap;
      }
      @media(max-width: 530px){
        button{
          margin-left: 0;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.dog-profile-sides-wrapper{
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @media(max-width: 658px){
    height: 230px;
    &.details{
      height:465px;
    }
  }
  @media(max-width: 594px){
    &.details{
      height:426px;
    }
  }
  @media(max-width: 450px){
    &.details{
      height:432px;
    }
  }
  @media(max-width: 400px){
    height: auto;
  }
}
.dog-profile-sides-elements-wrapper{
  display: flex;
  flex-direction: column
}
.dog-profile-mobile-button-wrapper{
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  button{
    margin-right: 20px;
    margin-left: 0;
  }
}
.dog-profile-sides-data{
  font-family:'Inter', sans-serif;
  font-weight:700;
  font-size:32px;
  color:black;
  span{
    margin-right:10px;
    padding: 0;
  }
  padding:15px 0;
  text-decoration:none;
  ul{
    li{
      display:flex;
      margin-bottom: 10px;
      font-size: 24px;
      font-style: italic;
      white-sapce: nowrap;
      p{
        font-style: initial;
        margin-right: 4px;
      }
    }
  }

  @media(max-width: 991px){
    font-size: 24px;
  }
  @media(max-width: 658px){
    width:50%;
    margin-right: 20px;
  }
  @media(max-width: 400px){
    width: 100%;
    margin: 0;
  }
}
.dog-profile-sides-clinic-data{
  font-family:'Inter', sans-serif;
  font-weight:700;
  font-size:20px;
  color:black;
}
.dog-profile-sides-headlines{
  font-family:'Inter', sans-serif;
  font-style:italic;
  font-weight:400;
  font-size:20px;
  color:#1C1B1F;
  @media(max-width: 658px){
    width:50%;
    margin-right: 20px;
  }
  @media(max-width: 400px){
    width: 100%;
    margin: 0;
  }
}
.dog-profile-rabies-info{
  width:100%;
  background-color:#51EA72;
  display:flex;
  justify-content:center;
  align-items:center;
  font-family:'Inter',sans-serif;
  font-weight:700;
  font-size:20px;
  color:#1C1B1F;
  border-radius:10px;
  padding:10px;
  span{
    margin-right:5px;
  }
  margin:5px 0;
}
.dog-profile-dog-image{
  width:100%;
  height:auto;
  border:5px solid #B12583;
  @media(max-width: 660px){
    width: 200px;
  }
}

