.about-container {
  width: 100%;
  @media screen and (min-width: $md) {
    height: 888px;
  }

  .about-wrapper {
    width: 100%;
    padding: 20px 33px;
    @media screen and (min-width: $sm) {
      max-width: 550px;
      margin: auto;
      padding: 0;
    }
    @media screen and (min-width: $sm) {
      max-width: 708px;
      margin: auto;
      padding: 0;
      padding-top: 50px;
    }
    @media screen and (min-width: $md) {
      max-width: 1220px;
      padding-top: 150px;

    }

    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .column {
        width: 100%;
        @media screen and (min-width: $md) {
          width: 50%;
          padding: 0 50px;
        }
      }
    }

    .section-header {
      font-size: 22px;
      font-family: 'Poppins', sans-serif;
      line-height: 28px;
      font-weight: 600;
      letter-spacing: 0px;
      margin-top: 65px;
      margin-bottom: 15px;
      @media screen and (min-width: $md) {
        font-size: 36px;
        line-height: 46px;
        font-weight: 600;
        margin-top: 0;
      }
    }

    .section-text {
      font-size: 13px;
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      letter-spacing: 0px;
      margin-bottom: 15px;
      line-height: 22px;
      text-align: left;
      color: #474747;
      @media screen and (min-width: $md) {
        font-size: 16px;
        line-height: 26px;
      }

      .section-text-highlight {
        font-size: 13px;
        font-family: 'Poppins', sans-serif;
        line-height: 28px;
        font-weight: 600;
        letter-spacing: 0px;
        margin-bottom: 15px;
        @media screen and (min-width: $md) {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }

    .search-button {
      font-size: 13px;
      font-family: 'Poppins', sans-serif;
      line-height: 28px;
      font-weight: 600;
      letter-spacing: 0px;
      margin-bottom: 15px;

      width: 141px;
      height: 49px;
      background: #b12583;
      border-radius: 5px;
      color: white;
      border: none;

      &:hover {
        background: #D34CA7;
      }

      @media screen and (min-width: $md) {
        font-size: 19px;
      }
    }

    .family-image {
      width: 100%;
    }
  }
}

.hero-container {
  width: 100%;
  height: 755px;
  background-image: url("../../../assets/about/aboutBackground.jpg");
  position: relative;
  background-repeat: no-repeat;
  background-position: -820px;
  background-size: cover;

  @media screen and (min-width: 696px) {
    background-position: -620px;
  }

  @media screen and (min-width: 890px) {
    background-position: -250px;
  }

  @media screen and (min-width: 1388px) {
    background-position: center;

  }

  @media screen and (min-width: $lg) {
    height: 820px;
  }

  .hero-wrapper {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    padding: 120px 33px 250px;

    .text-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 330px;
      @media screen and (min-width: $sm) {
        max-width: 430px;

      }
      @media screen and (min-width: $md) {
        max-width: 530px;

      }

      .hero-header {
        font-family: 'Poppins', sans-serif;
        color: #fff;
        margin-bottom: 15px;
        font-size: 29px;
        line-height: 39px;
        font-weight: 900;
        letter-spacing: 0px;
        @media screen and (min-width: $sm) {
          font-size: 41px;
          line-height: 47px;
          font-weight: 800;
          letter-spacing: 0px;
          margin-bottom: 35px;
        }
        @media screen and (min-width: $md) {
          font-size: 48px;
          line-height: 52px;
          font-weight: 800;
          letter-spacing: 0px;
        }
      }

      .hero-subheader {
        font-size: 13px;
        line-height: 33px;
        font-weight: 800;
        letter-spacing: 0px;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 15px;
        color: #fff;
        @media screen and (min-width: $sm) {
          font-size: 19px;
          line-height: 27px;
          font-weight: 800;
          letter-spacing: 0px;
        }
        @media screen and (min-width: $md) {
          font-size: 22px;
          line-height: 37px;

        }
      }

      .bottom-image {
        position: absolute;
        bottom: -20px;
      }
    }
  }
}

.story-container {
  width: 100%;
  background-color: #b12583;
  background-image: url("../../../assets/owner/dog-paws-purple-left.png");
  position: relative;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  @media screen and (min-width: $lg) {
    height: 900px;
  }
  @media screen and (min-width: $lg) {
    background-position: left;
    background-size: contain;

  }

  .story-wrapper {
    width: 100%;
    max-width: 484px;
    margin: auto;
    padding: 155px 33px 100px 33px;
    @media screen and (min-width: $sm) {
      max-width: 708px;
    }
    @media screen and (min-width: $md) {
      max-width: 1220px;
    }
    @media screen and (min-width: $lg) {
      height: 807px;
    }

    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .column {
        width: 100%;
        @media screen and (min-width: $sm) {
          width: 50%;
          padding: 0 20px;
        }
        @media screen and (min-width: $md) {
          padding: 0 50px;
        }

        .story-header {
          font-size: 29px;
          line-height: 33px;
          font-weight: 800;
          letter-spacing: 0px;
          color: #fff;
          font-family: 'Poppins', sans-serif;
          margin-bottom: 15px;
          @media screen and (min-width: $sm) {
            font-size: 41px;
            line-height: 47px;
          }
          @media screen and (min-width: $md) {
            font-size: 48px;
            line-height: 55px;

          }
        }

        .story-subheader {
          font-size: 13px;
          line-height: 19px;
          font-weight: 600;
          letter-spacing: 0px;
          color: #fff;
          font-family: 'Poppins', sans-serif;
          margin-bottom: 15px;
          @media screen and (min-width: $sm) {
            font-size: 19px;
          }
          @media screen and (min-width: $md) {
            font-size: 22px;
            line-height: 28px;

          }
        }

        .story-text {
          color: #FFFFFF;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 23px;
          font-family: 'Poppins', sans-serif;
          margin-bottom: 10px;

          &.story-highlight-text {
            font-weight: 800
          }

          @media screen and (min-width: $sm) {
            font-size: 14px;
          }
          @media screen and (min-width: $md) {
            font-size: 16px;
          }
        }
        .story-second-subheader{
          font-size: 27px;
          line-height: 33px;
          font-weight: 800;
          letter-spacing: 0px;
          color: #fff;
          font-family: 'Poppins', sans-serif;
          margin-bottom: 15px;
          @media screen and (min-width: $md) {
          font-size: 36px;
        }
          @media screen and (min-width: $lg) {
        }
        }
      }
    }
  }
}
