.pageWrapper_move {
  transform: translateX(-150px);
  transition: 600ms ease-in;
}

#pageWrapper {
  transition: 600ms;
}

#slider_menu {
  right: -100%;
}

.active_slider {
  transform: translateX(-100%);
  transition: 600ms;
}
@keyframes fadeInColor {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 50%;
    background-color: black;
  }
}
.empty_background {
  position: fixed;
  background-color: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 400ms;
  display: none;
  animation: fadeInColor 300ms ease-in-out;
}

.empty_background_active {
  display: block;
  z-index: 55;
  background-color: black;
  opacity: 50%;
}
