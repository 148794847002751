.payment-status-container{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  height:calc(100vh - 266px);
}
.payment-status-text{
  font-size:40px;
  color:black;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-bottom:20px;
}
.payment-status-success{
  font-size: 40px;
  color: #1c7a0e;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.payment-status-pending{
  font-size: 40px;
  color: #f56600;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  display:flex;
  align-items: center;
}
.payment-status-expired{
  font-size: 40px;
  color: #e10000;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
}
