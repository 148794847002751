.med-popup{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &:after{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    background-color: #000;
    opacity: .2;
    z-index: 2;
  }
  display: none;
  opacity: 0;
  &.active{
    display: block;
    opacity: 1;
  }
  .popup-wrapper{
    display: flex;
    flex-direction: column;
    background: #F5F5F5;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 118px 150px;
    z-index: 3;
    @media(max-width: 1500px){
      padding: 80px;
    }
    @media(max-width: 1200px){
      width: 80%;
    }
    @media(max-width: 991px){
      width: 100%;
      top: 25%;
    }
    @media(max-width: 576px){
      padding: 40px;
    }
    @media(max-width: 500px){
      padding: 25px;
    }
    .med-title{
      font-family: 'Inter', sans-serif !important;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      color: #1C1B1F;
      margin-bottom: 20px;
      @media(max-width: 576px){
        font-size: 28px;
        margin-bottom: 15px;
      }
      @media(max-width: 500px){
        font-size: 24px;
      }
    }
    .med-text{
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #1C1B1F;
      margin-bottom: 32px;
      @media(max-width: 576px){
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    .med-contact{
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      margin-bottom: 10px;
      text-decoration: none;
      @media(max-width: 576px){
        font-size: 16px;
        margin-bottom: 0;
      }
    }
    .med-close{
      position: absolute;
      top: 5%;
      right: 5%;
      width: 25px;
      height: 25px;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('../../../assets/close.svg');
      cursor: pointer;
      @media(max-width: 400px){
        width: 20px;
        height: 20px;
      }
    }
  }
}
.login-container{
  width:100%;
  height:100%;
  display:flex;
  flex-direction: column;
  .close-container{
    width:100%;
    display:flex;
    justify-content: flex-end;
    padding-top:25px;
    img{
      cursor:pointer;
    }
  }
  .login-column{
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    @media(min-width:$md){
      width:450px;
    }
    .login-title{
      display:none;
      @media(min-width: $md){
        font-size:50px;
        font-family:'Poppins', sans-serif;
        font-weight:800;
        margin-bottom:60px;
        display:block;
      }
    }
    .login-title-mobile{
      font-family:'Poppins', sans-serif;
      font-size:40px;
      font-weight:800;
      margin-bottom:60px;
      @media(min-width: $md){
       display:none;
      }
    }
    .login-button{
      width:100%;
      height:50px;
      border:none;
      outline:none;
      background-color:$green;
      border-radius:100px;
      margin-top:50px;
      color:#FFF;
      font-family:'Poppins', sans-serif;
      font-size:19px;
      font-weight:400;
      display:flex;
      justify-content: center;
      align-items: center;
      &:hover{
        background-color:$green-hover;
      }
      @media(min-width: $md){
        width:245px;
        height:35px;
        font-size:15px;
      }
      .login-loader {
        width: 40px;
        aspect-ratio: 2;
        --_g: no-repeat radial-gradient(circle closest-side,#fff 90%,#0000);
        background:
                var(--_g) 0%   50%,
                var(--_g) 50%  50%,
                var(--_g) 100% 50%;
        background-size: calc(100%/3) 50%;
        animation: l3 1s infinite linear;
      }
      @keyframes l3 {
        20%{background-position:0%   0%, 50%  50%,100%  50%}
        40%{background-position:0% 100%, 50%   0%,100%  50%}
        60%{background-position:0%  50%, 50% 100%,100%   0%}
        80%{background-position:0%  50%, 50%  50%,100% 100%}
      }
    }
    .login-problem{
      font-size:14px;
      font-family:'Poppins', sans-serif;
      font-weight:300;
      color:$graphite;
      text-decoration: underline;
      margin-top:150px;
    }
    .no-account-yet{
      font-family:'Poppins', sans-serif;
      font-size:14px;
      font-weight:300;
      color:$graphite;
      margin-top:45px;
      width:100%;
      text-align: center;
      @media(min-width:$md){
        text-align:left;
      }
      .register-button{
        font-family:'Poppins', sans-serif;
        font-size:14px;
        font-weight:700;
        color:$graphite;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
