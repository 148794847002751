.footer-container{
  width: 100%;
  background: $graphite;
  padding: 35px 30px;
  height:500px;
  @media screen and (min-width: 835px) {
    padding: 30px 25px;
    height:300px;
  }
  .footer-wrapper{
    width: 100%;
    display: flex;
    height:100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (min-width: $sm) {
      height: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
    .footer-column{
      display:flex;
      flex-direction: column;
      &.call-us{
        display:none;
        @media(min-width:965px){
          display:flex;
          flex-direction: column;
        }
      }
      .number{
        font-size:24px;
        font-family:'Poppins', sans-serif;
        font-weight:600;
        color:#fff;
      }
      .platform-image{
        width:140px;
        height:45px;
        cursor:pointer;
      }
      .logo{
        font-size:40px;
        font-family:'Poppins', sans-serif;
        font-weight:800;
        color:#fff;
        cursor: pointer;
        display:flex;
        flex-wrap:wrap;
        align-content: center;
        margin-bottom:20px;
        .highlight{
          font-size:40px;
          font-family:'Poppins', sans-serif;
          font-weight:800;
          color:$green;
        }
      }
      .footer-text{
        font-size:16px;
        font-family:'Poppins', sans-serif;
        font-weight:400;
        color:#fff;
        margin-bottom:25px;
        &.footer-address{
          line-height:25px;
          margin-bottom:40px;
        }
        .link{
          cursor: pointer;
          color:#fff;
          text-decoration:none;
          &:hover{
            color:#26C176;
          }
          &.number{
            font-size:24px;
            font-family:'Poppins', sans-serif;
            font-weight:700;
          }
        }
      }
    }
    .footer-right{
      display:flex;
      align-items: flex-end;
      justify-content: flex-start;
      flex-direction: column;
      gap:30px;
      @media(max-width:767px){
        margin-top:20px;
      }
      @media(min-width: $md){
        flex-direction: row;
        align-items: flex-start;
      }

    }
    .footer-left{
      display:flex;
      flex-direction: column;
      @media(min-width:$md){
        flex-direction: row;
        .footer-column{
          margin-right:90px;
        }
      }
    }
  }
}
