.form-wrapper {
  width: 100%;
  margin-top: 60px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 1111;

  .form-input {
    width: 100%;
    height: 45px;
    color: #626262;
    background-color: rgba(255, 255, 255, 1);
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 30px;
    border: 1px solid #626262;

    &:focus {
      color: #1982c2;
      background-color: rgba(233, 245, 252, 1);
      border-color: #d5e5ee
    }

    @media screen and (min-width: $md) {
      width: 50%;
    }
    @media screen and (min-width: $lg) {
      font-size: 15px;
    }
  }

  .form-textarea {
    width: 100%;
    margin-bottom: 30px;
    height: 162px;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    resize: none;

    &:focus {
      color: #1982c2;
      background-color: rgba(233, 245, 252, 1);
      border-color: #d5e5ee
    }
  }

  .form-button {
    margin-bottom: 60px;
    height: 40px;
    background-color: #b12583;
    outline: none;
    border: none;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: inherit;

    &:hover {
      background: #D34CA7;
      z-index: 1;
    }
  }
}

.contact-container {
  width: 100%;

  .contact-wrapper {
    width: 100%;
    padding: 0 33px;
    @media screen and (min-width: $sm) {
      max-width: 708px;
      margin: auto;
    }
    @media screen and (min-width: $md) {
      max-width: 1220px;
      margin: 150px auto auto;
    }

    .contact-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .contact-column {
        width: 100%;
        position: relative;
        @media screen and (min-width: $sm) {
          width: 50%;
        }

        .dog-image {
          width: 100%;
          margin-bottom: 90px;
          @media screen and (min-width: $sm) {
            position: absolute;
            top: -150px;
            width: 340px;
          }
          z-index: 1;
        }

        .empty-div {
          display: none;
          @media screen and (min-width: $sm) {
            display: block;
            height: 200px;
          }
        }

        .contact-questions {
          margin-bottom: 20px;
          font-size: 27px;
          line-height: 35px;
          font-weight: 600;
          letter-spacing: 0px;
          font-family: 'Poppins', sans-serif;
          margin-top: 150px;
          @media screen and (min-width: $md) {
            font-size: 36px;
            line-height: 46px;
            font-weight: 800;
            letter-spacing: 0px;
            margin-top: 0;
          }
        }

        .contact-text {
          margin-bottom: 20px;
          font-size: 17px;
          line-height: 24px;
          font-weight: 600;
          letter-spacing: 0px;
          font-family: 'Poppins', sans-serif;
          @media screen and (min-width: $md) {
            font-size: 22px;
            line-height: 32px;
            font-weight: 600;
            letter-spacing: 0px;
          }
        }

        .contact-box {
          width: 100%;
          background-color: #b12583;
          height: 298px;
          padding: 25px 20px 25px 30px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          @media screen and (min-width: $sm) {
            margin: 0 30px;
          }
          @media screen and (min-width: $md) {
            width: 342px;
            height: 384px;
            margin: auto;
          }

          .contact-elements-wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 13px 0;

            .contact-box-text {
              color: #fff;
              font-family: 'Poppins', sans-serif;
              font-size: 13px;
              line-height: 15px;
              font-weight: 400;
              margin-left: 30px;
              @media screen and (min-width: $lg) {
                font-size: 18px;
                line-height: 25px;

              }
            }
          }

          .contact-title {
            color: #fff;
            font-family: 'Poppins', sans-serif;
            font-size: 27px;
            line-height: 35px;
            font-weight: 600;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
.vet-page-container{
  min-height:2600px;
}
