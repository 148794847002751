.policy-screen{
  width:100vw;
  min-height:100vh;
  padding:170px 30px 10px 30px;
}
.header{
  font-size:20px;
  font-family:'Poppins', sans-serif;
  font-weight:700;
  margin-bottom:20px;
  color:#000;
}
.policy-text{
  font-size:14px;
  font-family:'Poppins', sans-serif;
  font-weight:300;
  color:#000;
  h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 10px;
    line-height:20px;
  }
  ul {
    list-style-type: disc;
    margin-left: 20px;
    li{
      margin-bottom:10px;
    }
  }
  div {
    margin-bottom: 20px;
  }
}
