.register-container{
  width:100%;
  max-width:1200px;
  height:100%;
  padding: 0 25px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media(min-width:$sm){
    //flex-direction: row;
    height:calc(100vh - 80px);
    padding:10px 0 0 0;
    align-items: flex-start;
    width:100%;
    max-width:100%;
  }
  .close-icon{
    position: absolute;
    top:25px;
    right:50px;
    cursor: pointer;
  }
  .register-column{
    width:100%;
    display:flex;
    height:100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media(min-width:$md){
      width:450px;
    }
    .have-an-account{
      font-size:14px;
      font-family:'Poppins', sans-serif;
      font-weight:300;
      color:$graphite;
      margin-top:45px;
      .login-link{
        font-weight:700;
        text-decoration: underline;
        cursor: pointer;
      }
      @media(min-width:$md){
        width:100%;
        text-align:left;
      }
    }
    .checkout-our-app{
      margin-top:40px;
      font-size:16px;
      font-family:'Poppins', sans-serif;
      font-weight:600;
      @media(min-width:$md){
        display:none;
      }
    }
    .apps-container{
      width:100%;
      display:flex;
      justify-content: space-around;
      align-items: center;
      margin-top:20px;
      a{
        padding:5px;
        background-color:black;
        border-radius:10px;
      }
      @media(min-width:$md){
        display:none;
      }
    }
    .register-title{
      display:none;
      @media(min-width:$md){
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 50px;
        color: $graphite;
        margin-bottom:35px;
        width:100%;
        text-align: left;
        display:block;
      }
    }
    .register-title-mobile{
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 50px;
      color: $graphite;
      margin-bottom:35px;
      width:100%;
      text-align: left;
      @media(min-width: $md){
        display:none;
      }
    }
    .register-text{
      font-family: 'Poppins', sans-serif;
      font-weight:400;
      font-size:16px;
      line-height: 30px;
      color: $graphite;
      width:100%;
      text-align: left;
      margin-bottom:70px;
      @media(min-width:$md){
        font-size:20px;
        font-weight:600;
      }
    }
    .register-form {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      width:100%;
      .contact-number {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .country-code{
          max-width:15% ;
          text-align:center;
          padding:0;
          option{
            width:100px;
          }
        }
        .phone-number{
          width:85%;
          margin-left:5px;
        }
      }
      .policy{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 15px;
        margin-bottom:12px;
        .policy-label{
          width:100%;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          color: #1c1b1f;
          display:flex;
          flex-direction: row;
          gap:10px;
          align-items: center;
          .label-text{
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 12px;
            color: $graphite;
            a{
              font-weight:700;
              text-decoration: underline;
              color: $graphite;
            }
          }
        }
        .policy-checkbox{
          border: 1px solid $graphite;
          border-radius: 8px;
          height: 20px;
          min-width: 20px;
          appearance: none;
          background-color: #fff;
          margin:0;
          display: grid;
          place-content: center;
          &.error{
            border:1px solid red;
          }
          &::before {
            content: "";
            width: 15px;
            height: 15px;
            border-radius: 10px;
            transform-origin: bottom left;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            box-shadow: inset 1em 1em $green;
          }

          &:checked {
            &::before {
              transform: scale(1)
            }
          }
        }
      }
      .register-button{
        width:100%;
        height:50px;
        border:none;
        outline:none;
        background-color:$green;
        color:#FFF;
        border-radius:100px;
        font-family:'Poppins', sans-serif;
        font-weight:500;
        font-size:19px;
        margin-top:50px;
        display:flex;
        justify-content: center;
        align-items: center;
        @media(min-width:$md){
          width:250px;
          font-weight:400;
          font-size:15px;
          height:35px;
        }
        .register-loader {
          width: 40px;
          aspect-ratio: 2;
          --_g: no-repeat radial-gradient(circle closest-side,#fff 90%,#0000);
          background:
                  var(--_g) 0%   50%,
                  var(--_g) 50%  50%,
                  var(--_g) 100% 50%;
          background-size: calc(100%/3) 50%;
          animation: l3 1s infinite linear;
        }
        @keyframes l3 {
          20%{background-position:0%   0%, 50%  50%,100%  50%}
          40%{background-position:0% 100%, 50%   0%,100%  50%}
          60%{background-position:0%  50%, 50% 100%,100%   0%}
          80%{background-position:0%  50%, 50%  50%,100% 100%}
        }
      }
  }
}


  .disclaimer{
    font-family: "Inter", sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1c1b1f;
  }

}
.register-second{
  display:none;
  @media(min-width:$sm){
    display:flex;
    flex-direction: column;
    padding:0 10px;
  }
  .register-info{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content:space-between;
    &>img{
      width:70%;
    }
    .app-stores-container{
      width:30%;
      display:flex;
      flex-direction: column;
      gap:10px;
    }
  }
  .register-info-text{
    font-size:20px;
    color:$graphite;
    font-family:'Poppins', sans-serif;
    margin-bottom:38px;
  }
}
