.hamburger-menu-container{
  width: 100%;
  overflow:scroll;
  background-color: #1D1D20;
  position: fixed;
  top: 0;
  bottom: 0;
  transition: 600ms;
  z-index: 5555;
  .hamburger-menu-wrapper{
    height:100%;
    padding: 0 25px 30px 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .rules-container{
      display:flex;
      height:20%;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap:40px;
      .hamburger-menu-nav-item-link{
        width: 100%;
        display: block;
        text-align:center;
        text-decoration: none;
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
        font-weight:300;
        color: #FFFFFF;
      }
    }
    .hamburger-menu-header{
      width:100%;
      padding:24px 0;
      display:flex;
      justify-content: space-between;
      align-items: center;
      .hamburger-menu-close-icon-container{
        .hamburger-menu-close-icon{
          width: 20px;
          height: 20px;
          position: relative;
          .hamburger-menu-close-line{
            position: absolute;
            width: 100%;
            height: 1px;
            top:9px;
            background-color: white;
            transform: rotate(45deg);
            transform-origin: center;
            &::before {
              content: '';
              position: absolute;
              width: 100%;
              height: 1px;
              background-color: white;
              transform: rotate(-90deg);
              transform-origin: center;
            }
          }
        }
      }
      .hamburger-header-logo {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
        font-weight: 800;
        color: #fff;
        cursor: pointer;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;

        .hamburger-logo-highlight {
          font-size: 28px;
          font-family: 'Poppins', sans-serif;
          font-weight: 800;
          color: $green;
        }
      }
    }
    .hamburger-menu-nav{
      width: 100%;
      margin-top:40px;
      .hamburger-menu-nav-list{
        width: 100%;
        .hamburger-menu-nav-item{
          width: 100%;
          display: flex;
          margin-bottom:40px;
          text-align: center;
          justify-content: center;
          align-items: center;
          .hamburger-menu-nav-item-link{
            width: 100%;
            display: block;
            text-align:center;
            text-decoration: none;
            font-size: 20px;
            font-family: 'Poppins', sans-serif;
            font-weight:300;
            color: #FFFFFF;
          }
        }
      }
    }
    .hamburger-menu-user-link,.hamburger-menu-user-logout{
      font-size: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight:300;
      color:#FFF;
      margin-bottom: 40px;
      text-decoration: none;
    }
    .hamburger-menu-user-logout{
      margin-top:40px;
    }
    .hamburger-menu-button{
      width:185px;
      background-color:transparent;
      color:#ffffff;
      min-height:50px;
      margin-bottom:25px;
      border-radius:100px;
      text-decoration: none;
      display:flex;
      align-items: center;
      justify-content: center;
      font-size:19px;
      font-weight:500;
      font-family:'Poppins', sans-serif;
      border:1px solid #FFF;
      &.register{
        background:$green;
        color:#FFF;
        border:none;
      }
    }
    .found-dog-container{
      width:100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top:20px;
      margin-bottom:20px;
      span{
        font-size:20px;
        font-family: 'Poppins', sans-serif;
        font-weight:400;
        color:#FFF;
      }
      a{
        font-size:30px;
        font-family: 'Poppins', sans-serif;
        font-weight:600;
        color:#FFF;
        text-decoration: none;
        margin-top:20px;
      }
    }
  }
}
