.owner-container {
  width: 100%;
  &.hero-section{
    background-image:url("../../../assets/owner/owner-hero.jpg");
    background-size:cover;
    background-position: right;
    background-repeat: no-repeat;
  }
  &.why-worth{
    background-image:url("../../../assets/owner/owner-why-worth.jpg");
    background-size:cover;
    background-position: right;
    background-repeat: no-repeat;
  }
  &.story-section{
    background-color:$green;
    background-image:url("../../../assets/owner/dog-paws-purple-left.png");
    padding-top:50px;
    padding-bottom:50px;
  }
  .owner-wrapper {
    width: 100%;
    max-width: 1220px;
    padding:0 25px;
    margin:0 auto;
    &.how-to-get{
      padding: 100px 0;
    }
    &.why-worth{
      @media(max-width:410px){
        padding:0 15px;
      }
    }
    &.hero-section{
      padding: 70px 25px 0 25px;
      @media(min-width:$md){
        padding:100px 25px 0 25px;
      }
    }
    .owner-row {
      width: 100%;
      display: flex;
      flex-direction:column;
      flex-wrap: wrap;
      &.story-section{
        @media(min-width:$sm){
          flex-direction: row;
        }
      }
      &.how-to-get{
        @media(min-width:$sm){
          flex-direction: row;
        }
      }
      .owner-column-how-to-get{
        width:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media(min-width:$sm){
          width:50%;
        }
        .register-your-dog{
          border-radius:100px;
          background-color:$green;
          text-decoration: none;
          min-width:185px;
          height:50px;
          font-size:19px;
          font-family: 'Poppins', sans-serif;
          font-weight:400;
          padding:0 30px;
          color:#FFF;
          display:flex;
          justify-content: center;
          align-items: center;
        }
        .owner-subtitle{
          font-size:20px;
          font-weight:400;
          font-family:'Poppins', sans-serif;
          color:$graphite;
          line-height:30px;
        }
        .map-image, .labrador-image{
          width:100%;
        }
      }
      &.why-worth{
        .carousel-container {
          position: relative;
          width: 100%;
          max-width: 600px;
          margin: 0 auto;       // Centers the carousel horizontally
          overflow: hidden;     // Hides parts of slides not in view

          // Optionally center the container’s content vertically as well
          display: flex;
          align-items: center;
          flex-direction: column;
          @media(min-width:1150px){
            display:none;
          }
        }

        .carousel-slide-wrapper {
          display: flex;
          transition: transform 0.3s ease-in-out;
          width: 100%;
        }

        .carousel-slide {
          flex: 0 0 100%;
          box-sizing: border-box;
          // Center the content inside each slide
          display: flex;
          justify-content: center;
          align-items: center;
          .owner-box{
            width:360px;
            height:480px;
            border-radius:15px;
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding:55px 30px 50px 30px;
            background-color:#FFF;
            text-align: center;
            svg{
              margin-bottom:55px;
              min-width:100px;
              min-height:80px;
            }
            .owner-box-title{
              font-size:20px;
              font-weight:600;
              margin-bottom:15px;
              font-family:'Poppins', sans-serif;
            }
            .owner-box-text{
              font-size:20px;
              line-height:30px;
              font-family:'Poppins', sans-serif;
            }
          }
        }

        .carousel-dots {
          display: flex;
          justify-content: center;
          margin-top: 16px;
        }

        .carousel-dot {
          width: 10px;
          height: 10px;
          margin: 0 6px;
          border-radius: 50%;
          background-color: #ccc;
          cursor: pointer;
          transition: background-color 0.2s ease;

          &.active {
            background-color: #333; // active dot color
          }
        }
        @media(min-width:$md){
          flex-direction: row;
          justify-content: space-between;
          .owner-column{
            width:360px;
            height:480px;
          }
        }
      }
      &.content-section{
        @media(min-width:$md){
          flex-direction: row;
        }
      }
      .owner-column {
        width: 100%;
        position: relative;
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &.story-section{
            @media(min-width:$md){
                width:50%;
              justify-content: flex-start;
            }
        }
        &.why-worth{
          @media(max-width:1150px){
            display:none;
          }
        }
        .owner-image-paws{
          display:none;
        }
        &.content-section{
          @media(min-width:$md){
            width:50%;
            .owner-image-paws{
              display:block;
            }
          }
        }
        .owner-box{
          width:100%;
          height:480px;
          border-radius:15px;
          display:flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          padding:55px 30px 50px 30px;
          background-color:#FFF;
          text-align: center;
          svg{
            margin-bottom:55px;
            min-width:100px;
            min-height:80px;
          }
          .owner-box-title{
            font-size:20px;
            font-weight:600;
            margin-bottom:15px;
            font-family:'Poppins', sans-serif;
          }
          .owner-box-text{
            font-size:20px;
            line-height:30px;
            font-family:'Poppins', sans-serif;
          }
        }
        .owner-text {
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          margin-bottom: 20px;
          line-height: 21px;
          max-width:450px;
          &.story-section{
            color:#FFF;
          }
        }

        .owner-image-small {
          width: 100%;
          display:block;
          &.content-section{
            @media(max-width:$md){
              max-width:450px;
            }
          }
          @media(min-width:$md){
            display:none;
          }
        }

        .owner-image-medium {
          width: 100%;
          display: none;

          @media(min-width:$md){
            display:block;
          }
        }
        .owner-image-paws{
          position: absolute;
          bottom: 0;
        }
        .map-image{
          width:100%;
          max-width:450px;
        }
        .labrador-image{
          width:100%;
          margin-top:20px;
        }
        .register-your-dog{
          border:none;
          background-color:$green;
          color:#FFF;
          width:150px;
          height:40px;
          border-radius:100px;
          font-size:13px;
          padding:10px 20px;
          font-family: 'Poppins', sans-serif;
          text-decoration: none;
        }
      }
    }

    .owner-title {
      font-family: 'Poppins', sans-serif;
      font-size: 37px;
      line-height: 41px;
      font-weight: 700;
      letter-spacing: 0px;
      text-align: center;
      &.hero-section{
        color:#FFF;
        @media(min-width:$md){
          text-align:left;
          font-size:60px;
          line-height:60px;
        }
      }
      &.why-worth, &.story-section{
        color:#FFF;
        font-size:37px;
        margin-bottom:30px;
        @media(max-width:700px){
          font-size:26px;
          margin-bottom:60px;
        }
      }

    }

    .owner-subtitle {
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      margin-top: 40px;
      margin-bottom: 40px;
      text-align: center;
      &.content-section{
        max-width:450px;
        text-align:left;
      }
      &.hero-section{
        color:#FFF;
        @media(min-width:$md){
          text-align:left;
          font-size:40px;
          line-height:40px;
          font-weight:800;
          margin-top:0;
          width:60%;
        }
      }
    }
  }

}
//.owner-page-container{
//  min-height:5700px;
//}
.owner-contact-container{
  margin:0 auto;
  display:flex;
  justify-content: center;
  .contact-container{
    height:100%;
    max-width:1200px;
  }
}
