.hero-wrapper {
  background-image: url('../../../assets/Home/mobile-home.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  min-height:900px;
  @media(min-width:650px){
    background-image: url('../../../assets/Home/pc-home.png');
    background-position:right;
    //background-position:-1000px;
  }
  //@media(min-width:1920px){
  //  background-image: url('../../../assets/Home/pc-home.jpeg');
  //  //background-position:-1000px;
  //}
  .home-hero-container {
    width: 100%;
    height: 100%;
    max-width: 822px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 25px;
    @media(min-width: 650px) {
      align-items: center;
    }

    .home-hero-title {
      color: #FFF;
      text-align: left;
      font-family: 'Poppins', sans-serif;
      font-size: 80px;
      font-style: normal;
      font-weight: 900;
      line-height: 65px;
      margin-bottom: 60px;
      @media(min-width: 650px) {
        margin-bottom: 30px;
      }

      .home-hero-title-highlight {
        color: $green;
        font-family: 'Poppins', sans-serif;
        font-size: 80px;
        font-style: normal;
        font-weight: 900;
        line-height: 65px;
      }
    }

    .home-hero-subtitle {
      color: #FFF;
      text-align: left;
      font-family: 'Poppins', sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 70px;
    }

    .code-container {
      margin-bottom: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .code-input {
        width: 100%;
        height: 50px;
        border-radius: 100px;
        background-color: #ffffff;
        outline: none;
        border: none;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #000000;
        text-align: center;
        padding: 10px 20px;

        &::placeholder {
          font-family: 'Poppins', sans-serif;
          font-size: 18px;
          font-weight: 500;
          color: #828282;
        }

        @media(min-width: 650px) {
          width: 333px;
          height: 55px;
          &::placeholder {
            font-weight: 400;
            font-size: 15px;
          }
        }
      }
    }

    .search-button {
      color: #FFF;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      font-weight: 600;
      outline: none;
      border: none;
      font-style: normal;
      width: 100%;
      height: 50px;
      border-radius: 100px;
      flex-shrink: 0;
      background-color: $green;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 13px;
        height: 15px;
        margin-right: 7px;
      }

      &:hover {
        background: $green-hover;
      }

      @media(min-width: 650px) {
        width: 333px;
        height: 55px;
      }
    }

    .icons-home-container {
      @media(max-width:649px){
        width:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        gap:40px;
        margin-top:70px;
      }
      @media(max-width:765px){
        display:flex;
        justify-content: center;
        align-items: center;
        gap:40px;
        margin-top:70px;
      }
      .icon {
        @media(max-width: 765px) {
          position: static;
        }
        position: absolute;
        bottom: 10%;
        right: 25px;
        cursor: pointer;
        width: 28px;
        height: 28px;

        &.fb {
          bottom: calc(10% + 25px + 28px);
        }
      }
  }
  }
}

