.header-hamburger-icon {
  margin-top: 12px;
  width: 25px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  &.open {
    span:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 3px;
      left: 8px;
    }

    span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 35px;
      left: 8px;
    }
  }

  display: block;
  @media screen and (min-width: $md) {
    display: none;
  }

  .header-hamburger-element {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #fff;;
    border-radius: 9px;
    opacity: 1;
    right: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    pointer-events: none;

    &:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 9px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 18px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &.center {
      width: 85%;
    }
  }
}

.login-component{
  position:absolute;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  z-index:10000;
  overflow:hidden;
  background-color:#FFF;
  padding:0 25px;
  min-height:900px;
  @media(min-width:$md){
    background-image:url("../../../assets/Login/pug.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: right;
    padding:0 100px;
  }
}
.register-component{
  position:absolute;
  top:0;
  left:0;
  width:100vw;
  min-height:900px;
  height:100vh;
  z-index:10000;
  overflow:hidden;
  background-color:#FFF;
  padding:0 25px;
  @media(min-width:$md){
    background-image:url("../../../assets/Login/login-bg.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: right;
    padding:0 100px;
  }
}
.header-container {
  transition: 2s;
  width: 100%;
  height: 60px;
  background-color:rgba(29,29,29,0.7);
  z-index: 40;
  position: fixed;
  @keyframes slideDown {
    0% {
      transform: translateY(-100%); /* Przesunięcie na górę */
    }
    100% {
      transform: translateY(0); /* Przesunięcie na dół */
    }
  }
  @media screen and (min-width: $md) {
    height: 80px;
  }
  top: 0;

  &.display {
    display: none;
  }

  &.primary {
    animation: slideDown 300ms ease-in-out;
  }

  .header-wrapper {
    //max-width: 1920px;
    height: 100%;
    padding: 6px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    margin:0;
    @media(min-width: $md) {
      padding-top: 15px;
      padding-bottom: 15px;

    }
    .header-logo-mobile{
      font-size: 28px;
      font-family: 'Poppins', sans-serif;
      font-weight: 800;
      color: #fff;
      cursor: pointer;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      .logo-highlight {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
        font-weight: 800;
        color: $green;
      }
      @media(min-width:$md){
        display:none;
      }
    }
    .header-navigation {
      height: 100%;
      display: none;
      @media(min-width: $md) {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        gap:30px;
        align-items: center;
      }

      .list-element{
        &:nth-child(4) {  // For the second child (help-line), to center it
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
        &:nth-child(5) {  // For the second child (help-line), to center it
          position: absolute;
          right: 25px;
          //transform: translateX(-50%);
        }
        .for-owner, .for-vet{
          font-family:'Poppins', sans-serif;
          font-size:16px;
          font-weight:500;
          color:#FFF;
          cursor: pointer;
          text-decoration: none;
          &:hover{
            color:$green-hover;
          }
        }
        .header-logo {
          font-size: 28px;
          font-family: 'Poppins', sans-serif;
          font-weight: 800;
          color: #fff;
          cursor: pointer;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          .logo-highlight {
            font-size: 28px;
            font-family: 'Poppins', sans-serif;
            font-weight: 800;
            color: $green;
          }
        }
        .help-line{
          font-size: 24px;
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          color: #fff;
          display:flex;
          align-items: center;
          text-decoration: none;
          img{
            margin-right:10px;
          }
        }
        .account-container{
          display:flex;
          justify-content: center;
          align-items: center;
          gap:40px;
          .link{
            text-decoration: none;
            font-size:16px;
            font-family: 'Poppins', sans-serif;
            color:#FFF;
            font-weight:500;
            cursor:pointer;
          }
          .dropdown-menu-container{
            position: relative;
            img{
              cursor:pointer;
            }
            .dropdown-menu{
              position: absolute;
              top:30px;
              right:0;
              width:175px;
              display:flex;
              flex-direction: column;
              padding:15px 10px;
              border:1px solid #636363;
              border-radius:10px;
              gap:10px;
              background-color:rgba(29,29,32,0.85);
              .dropdown-menu-el{
                font-size:14px;
                font-family: 'Poppins', sans-serif;
                font-weight:400;
                color:#FFF;
                padding:10px 7px;
                cursor: pointer;
                border-radius:5px;
                text-decoration: none;
                &:hover{
                  background-color:rgba(255,255,255,0.15);
                }
              }
            }
          }
        }
      }
    }
  }
}
