.user-profile-container {
  width: 100%;
  height: 100%;
  min-height: 1000px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  &.ignore {
    padding: 0
  }

  @media(max-width: 660px) {
    flex-direction: column-reverse;
  }

  .user-profile-sides {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &.smaller {
      width: 35%;
    }

    @media(max-width: 660px) {
      width: 100%;
    }


  }
}

.user-profile-sides-wrapper {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.user-profile-mobile-button-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;

  button {
    margin-right: 20px;
    margin-left: 0;
  }
}

.user-profile-sides-data {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 19px;
  color: $graphite;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  .send-sms{
    margin-left:10px;
    border:1px solid  $graphite;
    border-radius:100px;
    outline:none;
    font-size:14px;
    font-family: 'Poppins', sans-serif;
    font-weight:400;
    width:110px;
    height:35px;
    background:#FFF;
    &:hover{
      background:#EEE;
    }
    @media(min-width:$sm){
      margin-left:40px;
    }
  }
  span {
    margin-right: 10px;
    padding: 0;
  }

  padding: 15px 0;
  text-decoration: none;

  ul {
    li {
      display: flex;
      margin-bottom: 10px;
      font-size: 24px;
      font-style: italic;
      white-sapce: nowrap;

      p {
        font-style: initial;
        margin-right: 4px;
      }
    }
  }

  @media(max-width: $md) {
    font-size: 24px;
    width:100%;
  }
}

.user-profile-sides-headlines {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $graphite;
}

.user-profile-rabies-info {
  width: 100%;
  background-color: #51EA72;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #1C1B1F;
  border-radius: 10px;
  padding: 10px;
  @media(min-width:$md){
    width:400px;
  }
  span {
    margin-right: 5px;
  }

  margin: 5px 0;
}

.user-profile-dog-image {
  width: 100%;
  height: auto;
  border: 1px solid $graphite;
  border-radius:10px;
  @media(min-width:$md){
    width:400px;
    height:400px;
  }
}

.user-profile-user-header-container {
  height: 60px;
  width: 100%;
  background-color: #b12583;
  padding: 5px 15px;
  @media(max-width: 576px) {
    height: auto;
    padding: 10px 15px
  }
}

.user-profile-menu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  @media(max-width: 576px) {
    flex-direction: column;
  }
}

.user-profile-menu-element {
  padding: 0 45px;
  background: #b12583;
  color: #EBEBEB;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  line-height: 19px;

  &.current {
    background: #EBEBEB;
    color: #b12583;
  }

  @media(max-width: 768px) {
    text-align: center;
  }
  @media(max-width: 576px) {
    padding: 20px 10px;
  }
}

.add-id-image-file-wrapper {
  width: 100%;

  &.active {
    flex-direction: row;
    display: flex;
    margin-bottom: 10px;

    img {
      width: 60px;
    }

    img + div {
      border: 0;
    }
  }
}

.add-id-image-file-input-container {
  position: relative;
  height: 49px;
  border: 2px solid #b12583;
  border-radius: 10px;
  padding: 10px 22px;
  margin-bottom: 11px;

  label {
    white-space: nowrap;
  }

  .add-id-file-label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    cursor: pointer;

  }

  .add-id-file-icon {
    width: 30px;
    height: 30px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../assets/add_a_photo.png');
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
  }

  .add-id-remove-button {
    display: flex;
    background: transparent;
    border: 0;
    cursor: pointer;
    color: red;
    font-size: 16px;
    padding: 0;
    margin-top: 10px;

    &::after {
      content: "";
      width: 16px;
      height: 16px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../../../assets/delete.png');
      margin-left: 10px;
    }
  }

  .add-id-date-icon {
    width: 20px;
    height: 20px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../assets/calendar.png');
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.change-password
.my-pets-my-id {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  @media(max-width: 450px) {
    justify-content: center;
  }
}
.my-pets-my-id{
  display:flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap:wrap;
  gap:15px;
  @media(min-width:$md){
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.my-pets-pet {
  max-width: 145px;
  // width: 50%;
  height: 315px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media(min-width:$md){
    margin:0 15px;
    max-width: 200px;
  }
  @media(max-width: 718px) {
    margin: 5px;
  }
  @media(max-width: 576px) {
    margin: 15px 5px
  }
  .pet-details-button{
    width:110px;
    height:35px;
    border-radius:100px;
    outline:none;
    border:1px solid $graphite;
    font-family: 'Poppins', sans-serif;
    font-size:14px;
    font-weight:400;
    background-color:#FFF;
    color:$graphite;
  }
}

.my-pets-pet-image {
  width: 145px;
  height: 145px;
  border-radius:10px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../../assets/placeholderdogid.png');
  background-color: #C4C4C4;
  margin-bottom:20px;
  @media(min-width:$sm){
    width:190px;
    height:190px;
  }
}

.my-pets-pet-name {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
  text-align: left;
  margin-bottom: 5px;
  color: #1C1B1F;
}

.my-pets-valid-vaccination {
  font-family: 'Inter', sans-serif;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 12px;
  color: #35AD4F;
  width: 100%;
  text-align: left;
  @media(max-width: 450px) {
    text-align: left;
  }
}
.my-pets-empty-space {
  height: 12px;
  display: block;
}
.my-pets-vaccination-expired {
  font-family: 'Inter', sans-serif;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 12px;
  color: #D30000;
  width: 100%;
  text-align: left;
  @media(max-width: 450px) {
    text-align: left;
  }
}

.my-pets-add {
  width: 24px;
  height: 24px;
}

.my-ids-my-pets {
  width: 100%;
  min-height: calc(100vh - 300px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 90px 25px;
  @media(min-width: $md) {
    padding: 130px 100px;
    &.no-padding{
      padding:130px 25px;
    }
    &.more-padding{
      padding:130px 190px;
    }
  }

  .my-pets-title {
    font-size: 25px;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
    color: $graphite;
    margin-bottom: 30px;
    @media(min-width: $md) {
      font-size: 35px;
    }
  }

  .my-pets-subtitle {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: $graphite;
    margin-bottom: 50px;
    @media(min-width: $md) {
      width: 610px;
    }
  }

  .my-pets-add-pet {
    width: 145px;
    height: 145px;
    background-color: $green;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @media (max-width: 718px) {
        margin: 5px;
    }
    @media (max-width: 576px) {
      margin: 15px 5px;
    }
    @media(min-width:$sm){
      width:190px;
      height:190px;
    }
    &:hover {
      background-color: $green-hover;
    }

    .my-pets-add-pet-text {
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      width:90%;
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 15px;
      color: #FFFFFF;
      text-align: center;
      text-transform: uppercase;
      @media(min-width: $sm) {
        font-size:16px;
      }
    }
  }
}

.my-data-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 85px 25px;
  min-height:900px;
  @media(min-width: $md) {
    padding: 130px 110px;
  }

  .my-data-column {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .my-data-title {
      font-size: 35px;
      font-family: 'Poppins', sans-serif;
      font-weight: 800;
      color: $graphite;
      margin-bottom: 20px;
    }

    .my-data-subtitle {
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      color: $graphite;
      margin-bottom: 50px;
    }

    .my-data-form {
      width: 100%;
      height: 100%;
      @media(min-width: $md) {
        width: 385px;
      }

      .my-data-input-label {
        font-size: 15px;
        font-weight: 300;
        color: $graphite;
        font-family: 'Poppins', sans-serif;
        margin-left: 15px;

      }

      .my-data-input {
        height: 40px;
        background: #ffffff;
        border: 1px solid #1D1D1D;
        border-radius: 8px;
        min-width: 80px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 10px 22px;
        margin-bottom: 15px;
        margin-top: 10px;
      }

      .my-data-select {
        height: 40px;
        background: #ffffff;
        border: 1px solid $graphite;
        border-radius: 8px;
        min-width: 80px;
        margin-right: 5px;
        width: 100%;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        text-align: center;
        line-height: 24px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        margin-top: 10px;
      }
    }
  }
}
.add-id-arrow {
  width: 40px;
  margin-bottom: 40px;
  cursor:pointer;
}
.add-id {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .add-id-column {
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    @media(min-width:1210px){
      max-width:1210px;
      margin:0 auto;
    }
    .add-id-title {
      font-family: 'Poppins', sans-serif;
      font-weight: 800;
      font-size: 25px;
      color: $graphite;
      margin-bottom: 50px;
    }

    .add-id-step-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-bottom:50px;
      .add-id-step-title {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 700;
        color: $graphite;
        margin-bottom: 25px;
      }

      .add-id-steps {
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        @media(min-width: $md) {
          justify-content: center;
          gap:30px;
        }
        .add-id-step {
          display:flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .add-id-step-number{
            font-size:15px;
            font-family:'Poppins', sans-serif;
            font-weight:300;
            color:$graphite;
            margin-bottom:10px;
          }
          .add-id-step-indicator{
            width:130px;
            height:6px;
            border-radius:10px;
            background: #EEE;
            &.filled{
              background: $graphite;
            }
          }
        }
      }
    }
    .add-id-buttons-container{
      width:100%;
      display:flex;
      justify-content: flex-end;
      align-items: center;
      margin-top:25px;
      gap:25px;
      &.center{
        justify-content: center;
      }
      .add-id-button{
        border-radius:100px;
        border:1px solid $graphite;
        height:40px;
        width:110px;
        outline:none;
        background: #FFF;
        font-size:15px;
        font-family: 'Poppins', sans-serif;
        font-weight:400;
        color:$graphite;
        &:hover{
          background:#EEE;
        }
        &.filled{
        background: $green;
          color:#FFF;
          border:none;
          &:hover{
            background: $green-hover;
          }
      }
      }
    }
    .add-id-form{
      width:100%;
      height:100%;
      display:flex;
      flex-direction: column;
      @media(min-width: $md) {
        flex-wrap:wrap;
        flex-direction: row;
        gap:25px;
      }
      .photo-upload-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 75px;
        border: 1px solid $graphite;
        border-radius: 8px;
        cursor: pointer;
        position: relative;
        background-color: #fff;
        margin-top:10px;
        margin-bottom:15px;
        @media(min-width: $md) {
          height:100%;
        }
        .photo-upload-label {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          .photo-upload-icon {
            font-size: 36px;
            font-weight: 100;
            color: $graphite;
          }
        }
        .photo-upload-input {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
      .inputs-column{
        display:flex;
        flex-direction: column;
        width:100%;
        @media(min-width: $md){
          width:calc(33.33% - calc(50px / 3));
        }
      }
      .add-input-label{
        font-size:15px;
        font-family: 'Poppins', sans-serif;
        font-weight:300;
        color:$graphite;
        margin-left:15px;

      }
      .add-id-input-date, .add-id-select, .add-id-input{
        width:100%;
        height:40px;
        border:1px solid $graphite;
        border-radius:8px;
        background-color:#FFF;
        margin-top:10px;
        margin-bottom:15px;
        font-size:15px;
        font-family: 'Poppins', sans-serif;
        font-weight:300;
        color:$graphite;
        padding:0 15px;
        &.error{
          border:1px solid red;
        }
      }
      .additional{
        height:115px;
        resize:none;
        padding:15px;
        @media(min-width:$md){
          height:100%;
        }
      }
      .add-id-shorter-inputs-container{
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        justify-content: space-between;
        gap:0 15px;
        .shorter-container{
          width:calc(50% - calc(15px / 2));
        }
      }
    }
  }
}
.user-profile-sub-header{
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  color: $graphite;
  margin: 20px 0 40px 0;
  font-size:28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-align: left;
}
.user-profile-sides-header {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  font-size: 36px;
  color: $graphite;
  margin: 0 0 40px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-align: left;
  .button-edit-id{
    margin-left:40px;
    width:160px;
    height:35px;
    border-radius:100px;
    font-family:'Poppins', sans-serif;
    font-size:15px;
    font-weight:300;
    color:$graphite;
    background: #FFF;
  }
  @media(max-width: 991px) {
    font-size: 30px;
  }
  @media(max-width: 768px) {
    font-size: 28px;
  }
  @media(max-width: 660px) {
    flex-wrap: wrap;
  }
  @media(max-width: 530px) {
    button {
      margin-left: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
