.vet-hero-container {
  width: 100%;
  background-image: url('../../../assets/vet/vetHero.jpg');
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .vet-bottom-image{
    position:absolute;
    bottom:-20px;
  }
  .vet-hero-wrapper {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    padding: 250px 33px 250px;

    .vet-hero-text-wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      max-width: 430px;
      .vet-hero-title {
        font-size: 37px;
        line-height: 33px;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 30px;
      }
      .vet-hero-subtitle {
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
        letter-spacing: 0px;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 15px;
      }
      .vet-hero-desc {
        font-size: 19px;
        line-height: 25px;
        font-weight: 400;
        letter-spacing: 0px;
        font-family: 'Poppins', sans-serif;
        .highlight {
          font-weight: 700;
        }
        @media(min-width:$md){
          font-size:27px;
          line-height:27px;
        }
      }
    }
  }

  .bottom-image {
    position: absolute;
    bottom: -20px;
  }
}

.how-it-works-container {
  width: 100%;
  .how-it-works-wrapper {
    width: 100%;
    padding: 75px 33px;
    .how-it-works-row {
      display: flex;
      flex-direction:column;
      flex-wrap: wrap;
      @media(min-width:$md){
        flex-direction: row;
      }
      .how-it-works-column {
        position: relative;
        width: 100%;
        @media(min-width:$md){
          width:50%;
        }
        .image-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .how-it-works-image {
            width: 100%;
            z-index: 10;
            max-width: 484px;
          }
        }

        .icon-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 50px;
          margin-bottom:70px;
          .icon {
            margin: auto;
            width: 99px;
            height: 99px;
            margin-bottom: 50px;
          }
        }

        .how-it-works-text-wrapper {
          width: 100%;
          max-width: 540px;
          margin: auto;
          h2, p{
            max-width:400px;
          }
          .section-title {
            font-size: 37px;
            line-height: 33px;
            font-weight: 700;
            letter-spacing: 0px;
            font-family: 'Poppins', sans-serif;
            margin-bottom: 30px;
          }

          .section-subtitle {
            font-size: 13px;
            line-height: 19px;
            font-weight: 600;
            letter-spacing: 0px;
            font-family: 'Poppins', sans-serif;
            margin-bottom: 15px;
            @media(min-width:$md){
              font-size:19px;
              line-height:29px;
            }
          }

          .section-text {
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 35px;
            color: $graphite;
            vertical-align: baseline;
            max-width:100%;
            @media(min-width:$md){
              font-size:20px;
              line-height:30px;
            }
          }
        }
      }
    }
  }
}

.why-worth-container {
  width: 100%;
  .why-worth-wrapper {
    width: 100%;
    max-width: 446px;
    margin: auto;
    @media(min-width:$md){
      margin:0;
      padding:0 25px;
      max-width:100%;
    }
    .why-worth-row {
      display: flex;
      flex-direction: column;
      //padding:0 25px;
      max-width:770px;
      margin:0 auto;
      .why-worth-column {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-wrap: wrap;
        &.second{
          justify-content: center;
          align-items: center;
          @media(max-width:480px){
            padding:0 25px;
          }
          @media(min-width:$md){
            justify-content: space-between;
          }
        }
        .why-worth-element-wrapper {
          width: 100%;
          height:480px;
          text-align: center;
          border-radius:15px;
          box-shadow:0 4px 10px 0 rgba(0,0,0,0.25);
          padding:60px 15px 60px 15px;
          svg{
            margin-bottom:55px;
          }
          @media(min-width:$sm){
            width:360px;
          }
          @media(max-width:$md){
            margin-bottom:20px;
          }
          .why-worth-section-text {
            font-family: 'Poppins', sans-serif;
            font-size:19px;
            line-height:29px;
            margin-bottom: 30px;
          }
          .why-worth-icon{
            width: 70px;
            height: 70px;
            margin: auto auto 30px;
          }
        }
        .why-worth-title{
          font-size: 37px;
          line-height: 57px;
          font-weight: 700;
          letter-spacing: 0px;
          font-family: 'Poppins', sans-serif;
          margin-bottom: 30px;
          margin-top: 30px;
        }
      }
    }
  }
}
